import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
// import useWindowSize from "hooks/useWindowResize";

import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";

import ProtectedRoute from "./ProtectedRoute";

import { useSelector } from "react-redux";
import MetaTagsManager from "./MetaTagsManager";

import TMLazyLoader from "components/TMLazyLoader/TMLazyLoader";

const PageHome = TMLazyLoader(
  lazy(() => import("containers/PageHome/PageHome"))
);
const PageContact = TMLazyLoader(
  lazy(() => import("containers/PageContact/PageContact"))
);
const PageAbout = TMLazyLoader(
  lazy(() => import("containers/PageAbout/PageAbout"))
);

const OrdersHistory = TMLazyLoader(
  lazy(() => import("containers/TMOrdersHistory/OrdersHistory"))
);

const TMListingExperiencesPage = TMLazyLoader(
  lazy(
    () => import("containers/TMListingExperiencesPage/TMListingExperiencesPage")
  )
);
const TMListingExperiencesDetailPage = TMLazyLoader(
  lazy(
    () =>
      import(
        "containers/TMListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage.js"
      )
  )
);
const TMNewEventPage = TMLazyLoader(
  lazy(() => import("containers/TMNewEventPage/TMNewEventPage"))
);
const TMSearchPage = TMLazyLoader(
  lazy(() => import("containers/TMSearchPage/SearchPage"))
);
const TMPrivacyPolicyPage = TMLazyLoader(
  lazy(() => import("containers/TMPrivacyPolicy/TMPrivacyPolicy"))
);
const PageCustomerTerms = TMLazyLoader(
  lazy(() => import("containers/TMTermsConditions/customer"))
);
const PageOrganizerTerms = TMLazyLoader(
  lazy(() => import("containers/TMTermsConditions/organizer"))
);
const PageRefundPolicy = TMLazyLoader(
  lazy(() => import("containers/TMPrivacyPolicy/RefundPolicy"))
);
const PageFaq = TMLazyLoader(
  lazy(() => import("containers/TMPrivacyPolicy/Faq"))
);

const TMPageSignUp = TMLazyLoader(
  lazy(() => import("containers/TMPageSignUp/PageSignUp"))
);
const TMPageLogin = TMLazyLoader(
  lazy(() => import("containers/TMPageLogin/PageLogin"))
);

const ForgotPasssword = TMLazyLoader(
  lazy(() => import("containers/TMForgotPassword/ForgotPasssword"))
);
const ResetPassword = TMLazyLoader(
  lazy(() => import("containers/TMResetPassword/ResetPassword"))
);

const ProfilePage = TMLazyLoader(
  lazy(() => import("containers/TMAccountPage/AccountPage"))
);
const TMCartPage = TMLazyLoader(
  lazy(() => import("containers/TMCartPage/TMCartPage"))
);
const CheckOutPage = TMLazyLoader(
  lazy(() => import("containers/TMCheckOutPage/CheckOutPage"))
);
const PayPage = TMLazyLoader(
  lazy(() => import("containers/TMPayPage/PayPage"))
);
const PayFailPage = TMLazyLoader(
  lazy(() => import("containers/TMPayFailPage/TMPayFailPage"))
);
const TMBlogsPage = TMLazyLoader(
  lazy(() => import("containers/BlogPage/BlogPage"))
);
const TMBlogsPageSingle = TMLazyLoader(
  lazy(() => import("containers/BlogPage/BlogSingle"))
);
const TMPageReset = TMLazyLoader(lazy(() => import("containers/Reset/Reset")));
const TMStaffRegisterPage = TMLazyLoader(
  lazy(() => import("containers/TMStaffRegisterPage/TMStaffRegisterPage"))
);

const TMTicketPermitForm = TMLazyLoader(
  lazy(() => import("containers/TMTicketPermitForm/TMEventRegisterForm"))
);

const TMEventRegisterForm = TMLazyLoader(
  lazy(() => import("containers/TMEventRegisterForm/TMEventRegisterForm"))
);
export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  {
    path: "/category/:id",
    component: TMListingExperiencesPage,
  },
  {
    path: "/attraction/:experienceId",
    component: TMListingExperiencesDetailPage,
  },
  {
    path: "/events/category/:id",
    component: TMListingExperiencesPage,
  },
  {
    path: "/event/:experienceId",
    component: TMListingExperiencesDetailPage,
  },
  {
    path: "/cart",
    component: TMCartPage,
  },
  { path: "/payment-success", component: PayPage },
  { path: "/payment-failure", component: PayFailPage },

  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: TMPageSignUp },
  { path: "/login", component: TMPageLogin },

  { path: "/privacy-policy", component: TMPrivacyPolicyPage },
  { path: "/organizer-terms-and-conditions", component: PageOrganizerTerms },
  { path: "/customer-terms-and-conditions", component: PageCustomerTerms },
  { path: "/refund-policy", component: PageRefundPolicy },
  { path: "/faq", component: PageFaq },
  { path: "/forgot-password", component: ForgotPasssword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/search", component: TMSearchPage },
  { path: "/blog", component: TMBlogsPage },
  { path: "/blog/tag/:tagId", component: TMBlogsPage },
  { path: "/blog/topic/:topicId", component: TMBlogsPage },
  { path: "/blog", component: TMBlogsPage },
  { path: "/blog/post/:slug", component: TMBlogsPageSingle },
  { path: "/reset", component: TMPageReset },
  {
    path: "/new-event",
    component: TMNewEventPage,
  },
  {
    path: "/staff-register/:event",
    component: TMStaffRegisterPage,
  },
  {
    path: "/ticket-permit",
    component: TMTicketPermitForm,
  },
  {
    path: "/event-register",
    component: TMEventRegisterForm,
  },
  //
];

export const protectedPages: Page[] = [
  { path: "/checkout", component: CheckOutPage },
  { path: "/profile", component: ProfilePage },
  { path: "/orders", component: OrdersHistory },
];

const MyRoutes = () => {
  // let WIN_WIDTH = useWindowSize().width;
  let WIN_WIDTH = 1536;

  if (typeof window !== "undefined") {
    WIN_WIDTH = window.innerWidth;
  }

  const user = useSelector((state: any) => state.user);

  return (
    <>
      {window.location.pathname.split("/")[1] === "ar" ? (
        <BrowserRouter basename="/ar">
          <ScrollToTop />
          <MetaTagsManager />
          <SiteHeader />

          <Routes>
            {pages.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />;
            })}
            <Route element={<ProtectedRoute user={user} />}>
              {protectedPages.map(({ component, path }) => {
                const Component = component;
                return <Route key={path} element={<Component />} path={path} />;
              })}
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>

          {WIN_WIDTH < 768 && <FooterNav />}
          <Footer />
        </BrowserRouter>
      ) : window.location.pathname.split("/")[1] === "en" ? (
        <BrowserRouter basename="/en">
          <ScrollToTop />
          <MetaTagsManager />
          <SiteHeader />

          <Routes>
            {pages.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />;
            })}
            <Route element={<ProtectedRoute user={user} />}>
              {protectedPages.map(({ component, path }) => {
                const Component = component;
                return <Route key={path} element={<Component />} path={path} />;
              })}
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>

          {WIN_WIDTH < 768 && <FooterNav />}
          <Footer />
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <ScrollToTop />
          <MetaTagsManager />
          <SiteHeader />
          <Routes>
            {pages.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />;
            })}
            <Route element={<ProtectedRoute user={user} />}>
              {protectedPages.map(({ component, path }) => {
                const Component = component;
                return <Route key={path} element={<Component />} path={path} />;
              })}
            </Route>

            <Route path="*" element={<Page404 />} />
          </Routes>

          {WIN_WIDTH < 768 && <FooterNav />}
          <Footer />
        </BrowserRouter>
      )}
    </>
  );
};

export default MyRoutes;
