import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import { useSelector } from "react-redux";

function Navigation() {
  const {menuAttractions} = useSelector((state: any) => state.masterData);
  
  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NAVIGATION_DEMO.map((item) => {
        if(item.type === 'megaMenu'){
          item.megaMenu = menuAttractions;
        }
       return <NavigationItem key={item.id} menuItem={item} />
})}
    </ul>
  );
}

export default Navigation;
