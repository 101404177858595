/* eslint-disable jsx-a11y/anchor-is-valid */
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

import saFlag from 'images/svgs/sa.svg';
import aeFlag from 'images/svgs/ae.svg';
import caFlag from 'images/svgs/ca.svg';

import i18n from "i18n/i18n";

export const headerRegion = [
  
  {
    id: "uae",
    name: "UAE",
    description: "United Arab Emirates",
    href: "https://dubai.ticketmagic.me",
    hostname: 'dubai.ticketmagic.me',
    flag: aeFlag
  },
  {
    id: "saudi",
    name: "Saudi Arabia",
    description: "Saudi Arabia",
    href: "https://saudi.ticketmagic.me",
    hostname: 'saudi.ticketmagic.me',
    flag: saFlag
  },
  {
    id: "canada",
    name: "Canada",
    description: "Canada",
    hostname: 'ticketmagic.ca',
    href: "https://ticketmagic.ca",
    flag: caFlag
  },
];


const LangDropdown = ({
  panelClassName = "z-10 w-screen max-w-[280px] px-4 mt-4 right-0 sm:px-0",
}) => {
    
    
    const hostname = window?.location?.hostname ?? '';

    const currentSite = headerRegion.find(el => el.hostname === hostname);
   
  return (
    <div className="LangDropdown">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                
             group px-3 py-1.5 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                {
                    currentSite ? 
                    <><img src={currentSite.flag} alt={currentSite.name} className="w-8 h-8" />
                    </>
                    :
                    <><GlobeAltIcon className="w-[18px] h-[18px] opacity-80" />
                    <span className="ml-2 select-none">{i18n.t("region")}</span></>
                }
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`absolute ${panelClassName}`}>
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 lg:grid-cols-2">
                    {headerRegion.map((item, index) => (
                      <a
                        key={index}     
                        onClick={() => { 
                          
                          setTimeout(()=>{
                            window.location = `${item.href}`;
                          },500)
                          close();
                        }}
                        className={`cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                            hostname === item.hostname
                            ? "bg-gray-100 dark:bg-neutral-700"
                            : "opacity-80"
                        }`}
                      >
                        <div className="">
                          <p className="text-sm font-medium "> <img src={item.flag} alt={item.name} className="w-8 h-8" /> {item.name}</p>
                          {/* <p className="text-xs text-gray-500 dark:text-neutral-400">
                            {item.description}
                          </p> */}
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
export default LangDropdown;
