/* eslint-disable no-throw-literal */
import axios from "axios";
import {
  setCategories,
  setMenuAttractions,
  setHomeBanners,
} from "store/reducers/masterData";
//ADMIN APIS ACTION
const apiGetCategories = async (dispatch) => {
  const url = `/categories`;
  try {
    const res = await axios.get(url);

    if (res.status === 200) {
      const resData = res?.data;
      if (dispatch) dispatch(setCategories({ categories: resData }));
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetCategoriesTabs = async (dispatch) => {
  const url = `/home-page-cat`;
  try {
    const res = await axios.get(url);

    if (res.status === 200) {
      // dispatch(setCategories( {categories: resData}));
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetAttractionEvents = async (id, page = 0) => {
  const url = `/all-events/category/${id}/${page}`;
  try {
    const res = await axios.get(url);

    if (res.status === 200) {
      // dispatch(setCategories( {categories: resData}));
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetEventDetails = async (id) => {
  const url = `/attraction/details/${id}`;
  try {
    const res = await axios.get(url);

    if (res.status === 200) {
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

// const apiGetPrioSlots = async (eventId, totalTickets, date) => {
//   const url = `/attraction/prio-tickets/${eventId}/get-time-slots/${date}/${totalTickets}/`;
//   try {
//     const res = await axios.get(url);

//     if (res.status === 200) {
//       return res?.data;
//     } else {
//       throw "Error Occoured";
//     }
//   } catch (error) {
//     console.log(error);
//     return null;
//   }
// };

const apiGetPrioSlots = async (eventId, totalTickets, date) => {
  // console.log(date);
  const url = `/event-time-slots`;
  try {
    // const res = await axios.post(url, {event_id:eventId, date:date, count: totalTickets});
    const res = await axios.post(
      url,
      JSON.stringify({
        event_id: eventId,
        ticket_date: date,
        ticket_count: totalTickets,
      })
    );

    if (res.status === 200) {
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    // throw "Error Occoured";
    return null;
  }
};

const formatMenuAttractions = (rawData) => {
  return rawData.map((el) => {
    return {
      id: el?.id,
      href: `/category/${el.href || el.id}`,
      image: `https://portal.ticketmagic.me/uploads/banners/${el?.thumbnail}`,
      title: el?.category_name,
      items: el?.event_list.slice(0, 6).map((ev) => {
        return {
          id: ev.event_id,
          name: ev.title,
          href: `/attraction/${ev.href}`,
        };
      }),
    };
  });
};

const apiGetMenuAttractions = async (dispatch) => {
  const url = `/menu-categories`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      const formattedData = formatMenuAttractions(res.data);

      if (dispatch)
        dispatch(setMenuAttractions({ attractions: formattedData }));
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const formatHomeBanners = (rawData) => {
  return rawData.map((el) => {
    return {
      src: `https://portal.ticketmagic.me/uploads/banners/${el.image}`,
      text: el?.heading,
      btnText: el?.button_title,
      btnLink: el?.button_link,
      is_button: el?.is_button,
    };
  });
};
const apiGetHomeBanners = async (dispatch) => {
  const url = `/banners`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      const formattedData = formatHomeBanners(res.data);
      if (dispatch) dispatch(setHomeBanners({ homeBanners: formattedData }));
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetLiveSearch = async (postData) => {
  const url = `/all-attractions?search=${postData.search}`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetAd = async (postData) => {
  const url = `/get-site-ads`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    throw "Error Occoured";
  }
};

const apiGetPromoPopups = async () => {
  const url = `/get-discount-popups`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    throw "Error Occoured";
  }
};

const apiGetSections = async () => {
  const url = `/sections`;
  try {
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    throw "Error Occoured";
  }
};

const apiGetMetaTagsSlugs = async (payload) => {
  const { page, slug } = payload;
  const url = `/get-meta-info?page=${page}&slug=${slug}`;
  try {
    // const res = await axios.post(url, {event_id:eventId, date:date, count: totalTickets});
    const res = await axios.post(url, {});
    if (res.status === 200) {
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetWeatherDetails = async (payload) => {
  const { region } = payload;

  // try {
    // /get-weather
  //   const res = await axios.post("/banners", JSON.stringify({ region }));
  //   if (res && res.status === 200) {
  //     return res?.data;
  //   } else {
  //     throw "Error Occoured";
  //   }
  // } catch (error) {
  //   console.log(error);
  //   return null;
  // }
  return null;
};

const apiGetKeywordsSection = async (id) => {
  const url = `/footer-attractions`;
  try {
    const res = await axios.get(url);

    if (res.status === 200) {
      return res?.data;
    } else {
      throw "Error Occoured";
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const apiGetVenueMapSvgFile = async (path) => {
 
  try {
      const response = await fetch(path);
      const text = await response.text();
      return text;
  }
    catch (error) {
      console.log(error);
      return null;
    }
};

export {
  apiGetCategories,
  apiGetCategoriesTabs,
  apiGetAttractionEvents,
  apiGetEventDetails,
  apiGetPrioSlots,
  apiGetMenuAttractions,
  apiGetHomeBanners,
  apiGetLiveSearch,
  apiGetAd,
  apiGetPromoPopups,
  apiGetSections,
  apiGetMetaTagsSlugs,
  apiGetWeatherDetails,
  apiGetKeywordsSection,
  apiGetVenueMapSvgFile
};
