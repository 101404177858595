import Logo from "shared/Logo/Logo";
import config from "config/config";
// import SocialsList1 from "shared/SocialsList1/SocialsList1";
import visaPng from "images/visa01.png";
import mastercardPng from "images/mastercard.svg";
import madacardPng from "images/Mada_Logo.svg.png";
import { CustomLink } from "data/types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import i18n from "i18n/i18n";

import ButtonCircle from "shared/Button/ButtonCircle";
import { Formik } from "formik";
import Input from "shared/Input/Input";
import { apiUserNewsletter } from "apis/user";
import { toast } from "react-toastify";
import * as Yup from "yup";
import TMWeatherWidget from "components/TMWeatherWidget/TMWeatherWidget";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {
  const [successMessage, setSuccessMessage] = useState(null);

  return (
    <footer aria-label="Site Footer" className="bg-slate-800 ">
      <div className="max-w-screen-xl px-4 py-16 mx-auto space-y-8 sm:px-6 lg:space-y-16 lg:px-2">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div>
            <Logo />
            <p className="font-medium text-white mt-4">
              {i18n.t("FooterHeadOffice")}
            </p>
            <p className="max-w-xs mt-2 text-white">
              {i18n.t("FooterAddress")} <br />
              <br />
            </p>
            <p
              className={`max-w-xs mt-2 items-end text-white ${
                i18n.language === "ar" ? "hardText-ltr" : ""
              }`}
            >
              <a href={`tel:${config.contacts.phone}`}>
                {" "}
                {i18n.t("PageContactPhone")} : {`${config.contacts.phone}`}{" "}
              </a>{" "}
              <br />
              <a href={`https://wa.me/${config.contacts.whatsapp}`}>
                {" "}
                {i18n.t("PageContactWA")} : {`${config.contacts.whatsapp}`}{" "}
              </a>{" "}
              <br />
              <a href={`mailto:${config.contacts.mail}`} className="mt-2">
                {" "}
                {i18n.t("PageContactEmail")} : {`${config.contacts.mail}`}
              </a>
            </p>

            <ul className="flex gap-6 mt-8">
              <li>
                <a
                  href={config.contacts.facebook}
                  rel="noreferrer"
                  target="_blank"
                  className="text-white transition hover:opacity-75"
                >
                  <span className="sr-only">Facebook</span>

                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href={config.contacts.instagram}
                  rel="noreferrer"
                  target="_blank"
                  className="text-white transition hover:opacity-75"
                >
                  <span className="sr-only">Instagram</span>

                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href={config.contacts.twitter}
                  rel="noreferrer"
                  target="_blank"
                  className="text-white transition hover:opacity-75"
                >
                  <span className="sr-only">Twitter</span>

                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href={config.contacts.linkedIn}
                  rel="noreferrer"
                  target="_blank"
                  className="text-white transition hover:opacity-75"
                >
                  <span className=" sr-only">LinkedIn</span>

                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M 21.800781 0 L 2.199219 0 C 1 0 0 1 0 2.199219 L 0 21.800781 C 0 23 1 24 2.199219 24 L 21.800781 24 C 23 24 24 23 24 21.800781 L 24 2.199219 C 24 1 23 0 21.800781 0 Z M 7 20 L 3 20 L 3 9 L 7 9 Z M 5 7.699219 C 3.800781 7.699219 3 6.898438 3 5.898438 C 3 4.800781 3.800781 4 5 4 C 6.199219 4 7 4.800781 7 5.800781 C 7 6.898438 6.199219 7.699219 5 7.699219 Z M 21 20 L 17 20 L 17 14 C 17 12.398438 15.898438 12 15.601563 12 C 15.300781 12 14 12.199219 14 14 C 14 14.199219 14 20 14 20 L 10 20 L 10 9 L 14 9 L 14 10.601563 C 14.601563 9.699219 15.601563 9 17.5 9 C 19.398438 9 21 10.5 21 14 Z"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href={config.contacts.youtube}
                  rel="noreferrer"
                  target="_blank"
                  className="text-white transition hover:opacity-75"
                >
                  <span className="sr-only">Youtube</span>

                  <svg
                    className="w-8 h-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
                      fill="white"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3">
            <div>
              <p className="font-medium text-white">{i18n.t("FooterTandC")}</p>
              <nav aria-label="Footer Navigation - Services" className="mt-3">
                <ul className="space-y-4 text-sm">
                  <li>
                    <Link
                      to={"/customer-terms-and-conditions"}
                      className="text-white transition hover:opacity-75"
                    >
                      {i18n.t("FooterCustomerTandC")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/organizer-terms-and-conditions"}
                      className="text-white transition hover:opacity-75"
                    >
                      {i18n.t("FooterOrganizerTandC")}
                    </Link>
                  </li>
                </ul>
              </nav>
              <br />

              <p className="font-medium text-white mt-5">
                {i18n.t("FooterPolicies")}
              </p>
              <nav aria-label="Footer Navigation - Services" className="mt-3">
                <ul className="space-y-4 text-sm">
                  <li>
                    <Link
                      to={"/privacy-policy"}
                      className="text-white transition hover:opacity-75"
                    >
                      {i18n.t("FooterPrivacyPolicy")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/refund-policy"}
                      className="text-white transition hover:opacity-75"
                    >
                      {i18n.t("FooterRefundPolicy")}
                    </Link>
                  </li>
                </ul>
              </nav>
              <br />
              {/* <p className="font-medium text-white mt-5">Frequently asked questions</p> */}
              <nav aria-label="Footer Navigation - Services" className="mt-3">
                <ul className="space-y-4 text-sm">
                  <li>
                    <Link
                      to={"/about"}
                      className="text-white transition hover:opacity-75"
                    >
                      {i18n.t("HeaderAboutUs")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/faq"}
                      className="text-white transition hover:opacity-75"
                    >
                      {i18n.t("FooterFaqs")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/blog"}
                      className="text-white transition hover:opacity-75"
                    >
                      {i18n.t("HeaderBlog")}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div
              className={`flex justify-center ${
                config.region === "uae" ? "" : "hidden"
              }`}
            >
              <div className="flex flex-col items-center">
                <h2 className="font-medium text-white text-center">
                  {i18n.t("FooterDownloadApp")}
                </h2>
                <a
                  href="https://apps.apple.com/ae/app/ticket-magic/id1553029523"
                  className="flex mt-3 w-40 h-14 bg-black text-white rounded-xl items-center justify-center"
                >
                  <div className="mx-3">
                    <svg viewBox="0 0 384 512" width="20">
                      <path
                        fill="currentColor"
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                      />
                    </svg>
                  </div>
                  <div>
                    <div className="text-xs">Download on the</div>
                    <div className="text-xl font-semibold font-sans -mt-1">
                      App Store
                    </div>
                  </div>
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.ticketmagic.app"
                  className="flex mt-3 w-40 h-14 bg-black text-white rounded-lg items-center justify-center"
                >
                  <div className="mx-1">
                    <svg viewBox="30 336.7 120.9 129.2" width="20">
                      <path
                        fill="#FFD400"
                        d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
                      />
                      <path
                        fill="#FF3333"
                        d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"
                      />
                      <path
                        fill="#48FF48"
                        d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"
                      />
                      <path
                        fill="#3BCCFF"
                        d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"
                      />
                    </svg>
                  </div>
                  <div>
                    <div className="text-xs">GET IT ON</div>
                    <div className="text-xl font-semibold font-sans -mt-1">
                      Google Play
                    </div>
                  </div>
                </a>
                <br />
                <h2 className="font-medium text-white text-center mt-2 mb-3">
                  {i18n.t("WeatherinDubai")}
                </h2>
                <TMWeatherWidget />
              </div>
            </div>

            <div>
              {/* <iframe className="aspect-square" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14430.190956890257!2d55.3603096!3d25.285795!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d2dff5d61ab%3A0x7b6f9b922a57e659!2sTicket%20Magic!5e0!3m2!1sen!2sae!4v1685450720365!5m2!1sen!2sae"  width="100%" height="300" loading="lazy" ></iframe> */}
              <h2 className="font-medium text-white text-center">
                {i18n.t("FooterWeAccept")}
              </h2>
              <div className="grid justify-center mt-2 gap-4">
                <img className="w-16" src={visaPng} alt="visa" />
                <img className="w-16" src={mastercardPng} alt="mastercard" />
                <img className="w-16" src={madacardPng} alt="Mada Pay" />
              </div>
            </div>
            <div className={["uae"].includes(config.region) ? "hidden" : ""}>
              <p className="font-medium text-white text-center">
                {i18n.t("subscribeSectionJoin")}
              </p>
              <Formik
                initialValues={{
                  email: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting, resetForm }
                ) => {
                  try {
                    setStatus({ success: false });
                    setSubmitting(true);
                    setSuccessMessage(null);
                    const res = await apiUserNewsletter(values);
                    if (res && res?.success === true) {
                      setSuccessMessage(res?.message);
                      toast.success(res?.data?.message);
                      resetForm();
                    } else if (res && res?.success === false) {
                      if (res?.errors) {
                        const { email } = res.errors;
                        setErrors({ email });
                      }
                    }
                  } catch (err) {
                    console.log(err);
                    setStatus({ success: false });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    className="mt-2 relative max-w-sm"
                  >
                    <Input
                      required
                      aria-required
                      placeholder={"Email"}
                      type="email"
                      rounded="rounded-full"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                    />
                    <ButtonCircle
                      type="submit"
                      disabled={isSubmitting}
                      className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
                    >
                      <i className="las la-arrow-right text-xl"></i>
                    </ButtonCircle>
                  </form>
                )}
              </Formik>
              {successMessage && (
                <p className=" text-green-500  my-2 mt-2">{successMessage}</p>
              )}
            </div>
          </div>
        </div>

        <p className="text-center text-white">
          {new Date().getFullYear()} &copy; Ticket Magic.{" "}
          {i18n.t("FooterCopyRights")}  |{" "}
          {" "} {i18n.t("FooterDevelopedBy")} 
        </p>
      </div>
    </footer>
  );
};

export default Footer;
