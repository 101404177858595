// types
import { createSlice } from "@reduxjs/toolkit";
const sectionsData = [
  {
    title: "Duabi Fun",
    hot_deals: [
      {
        url: "https://https://entertainment.emaar.com/attraction/dubai-aquarium-and-underwater-zoo",
        image:
          "https://dubai.ticketmagic.me//uploads/events/thumbnails/50145953728032023104805",
        title: "LEGOLAND® Water Park",
        price: 295,
      },
      {
        url: "https://https://entertainment.emaar.com/attraction/dubai-aquarium-and-underwater-zoo",
        image:
          "https://dubai.ticketmagic.me//uploads/events/thumbnails/50145953728032023104805",
        title: "Dental Care Packages at Sikka Dental Clinic",
        price: 295,
      },
      {
        url: "https://entertainment.emaar.com/attraction/dubai-aquarium-and-underwater-zoo",
        image:
          "https://dubai.ticketmagic.me//uploads/events/thumbnails/50145953728032023104805",
        title: "EKart Zabeel",
        price: 295,
      },
      {
        url: "https://https://entertainment.emaar.com/attraction/dubai-aquarium-and-underwater-zoo",
        image:
          "https://dubai.ticketmagic.me//uploads/events/thumbnails/50145953728032023104805",
        title: "Mega Discount offer @ Dubai Frame Ticket",
        price: 21,
      },
    ],
    featured_deals: {
      title: "Featured Deals on Dubai Fun",
      description:
        "Time to pamper yourself in Dubai! We’ve got the best value deals for you including relaxation, facials, manicures, pedicures, haircuts, hair removal, weight loss treatments and so much",
      deals: [
        {
          id: 168,
          title: "Avail 30% discount @ iFly Dubai for First-time Flyers.",
          url: "https://entertainment.emaar.com/attraction/dubai-aquarium-and-underwater-zoo",
          address:
            " City Centre Mirdif، Ghoroob, Sheikh Mohammed Bin Zayed Road",
          price: "150.50",
          featuredImage: "125051565928022023104009.png",
          listingCategoryId: 42,
          bestSeller: true,
          prev_price: "215",
          thumbnails: [],
        },

        {
          id: 168,
          title: "60 Mins Traditional Boat Tour - Dubai Canal Abra Tour",
          url: "attraction/60-mins-traditional-boat-tour-dubai-canal-abra-tour",
          address: " Next To Dubai Festival City Marine Transport Station",
          price: "60",
          featuredImage: "82611082123082022113556.png",
          listingCategoryId: 42,
          bestSeller: false,
          // "prev_price": "215",
          thumbnails: [],
        },
        {
          id: 168,
          title: "Buy 3 Get 1 @ Creek Park Bird Show.",
          url: "/attraction/creek-park-bird-show",
          address: "Creek Park, Gate 1. Umm Hurrair 2, Dubai",
          price: "50.50",
          featuredImage: "26925210904102021145344.webp",
          listingCategoryId: 42,
          bestSeller: true,
          // "prev_price": "215",
          thumbnails: [],
        },
      ],
    },
    categorical_deals: [
      {
        category: "Keep Calm and Cruise",
        attractions_list: [
          {
            id: 1952,
            title: "The Yellow Boats 60-minute Dubai Marina Cruise",
            href: "the-yellow-boats-60-minute-dubai-marina-cruise",
            address:
              "The Yellow Boats, Dubai Marina Walk, Opposite Spinneys Supermarket",
            price: "120.00",
            featuredImage: "128597798303042023113521",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1895,
            title: "HERO Signature Afternoon Boat Tour - 2 people",
            href: "hero-signature-afternoon-boat-tour-2-people",
            address:
              "Hero OdySea Dubai Boat tours - Dubai Harbour - Marina Zone A",
            price: "795.00",
            featuredImage: "72755711820032023114522",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1894,
            title: "HERO Signature Mid-morning Boat Tour - 2 people",
            href: "hero-signature-mid-morning-boat-tour-2-people",
            address:
              "Hero OdySea Dubai Boat tours - Dubai Harbour - Marina Zone A",
            price: "795.00",
            featuredImage: "98270782120032023113236",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1892,
            title: "HERO OdySea Sunset Boat Tour - 2 people",
            href: "hero-odysea-sunset-boat-tour-2-people",
            address: "Hero OdySea HQ - Dubai Harbour - Marina Zone A",
            price: "895.00",
            featuredImage: "135326553320032023104812",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 434,
            title: "60 Mins Traditional Boat Tour - Dubai Canal Abra Tour",
            href: "60-mins-traditional-boat-tour-dubai-canal-abra-tour",
            address: "Next To Dubai Festival City Marine Transport Station",
            price: "60.00",
            featuredImage: "82611082123082022113556.png",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1948,
            title:
              "Sharjah Boat Tour \u2013 Private (30 minutes tour for up to 11 guests)",
            href: "sharjah-boat-tour-private-30-minutes-tour-for-up-to-11-guests",
            address: "Al Qasba - Sharjah - United Arab Emirates",
            price: "240.00",
            featuredImage: "188073916631032023144337",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1870,
            title: "Royal Dinner Cruise along Dubai Canal",
            href: "royal-dinner-cruise-along-dubai-canal",
            address:
              "Dubai Creek Harbour Sales Pavilion - Emaar - Dubai - United Arab Emirates",
            price: "310.00",
            featuredImage: "144214250914032023103752",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1869,
            title: "Royal Dinner Cruise at the Marina without Transfers",
            href: "royal-dinner-cruise-at-the-marina-without-transfers",
            address:
              "Dubai Harbour Cruise Terminal, Zone C , station P3 , Pontoon",
            price: "310.00",
            featuredImage: "143442472114032023102104.png",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1860,
            title: "Dubai Marina Dinner Cruise with Live Music",
            href: "dubai-marina-dinner-cruise-with-live-music",
            address:
              "Xclusive Yachts - Boarding Point, Marina Level - Al Hubob Street - Dubai - United Arab Emirates",
            price: "160.00",
            featuredImage: "162244302309032023170402",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1859,
            title: "Dubai Marina Yacht Tour with BBQ Lunch",
            href: "dubai-marina-yacht-tour-with-bbq-lunch",
            address:
              "Xclusive Yachts - Boarding Point, Marina Level - Al Hubob Street - Dubai - United Arab Emirates",
            price: "160.00",
            featuredImage: "194007835609032023164348",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1862,
            title: "Sunset Yacht Shared Tour with Barbecue",
            href: "sunset-yacht-shared-tour-with-barbecue",
            address:
              "Xclusive Yachts - Boarding Point, Marina Level - Al Hubob Street - Dubai - United Arab Emirates",
            price: "160.00",
            featuredImage: "148180248109032023174639",
            listingCategoryId: 10,
            thumbnails: [],
          },
          {
            id: 1863,
            title: "Dubai Marina Afternoon Yacht Tour with BBQ - Sharing Basis",
            href: "dubai-marina-afternoon-yacht-tour-with-bbq-sharing-basis",
            address:
              "Xclusive Yachts - Boarding Point, Marina Level - Al Hubob Street - Dubai - United Arab Emirates",
            price: "160.00",
            featuredImage: "133101072010032023114244",
            listingCategoryId: 10,
            thumbnails: [],
          },
        ],
      },
    ],
    tags: [
      {
        id: "23",
        slug: "aqua",
        label: "Dolphin",
      },
    ],
  },
];

// initial state
const initialState = {
  categories: null,
  menuAttractions: null,
  homeBanners: null,
  homeSections: null,
  modalGalleryImages: [
    {
      url: "https://dubai.ticketmagic.me/uploads/events/thumbnails/150408594206062023134837",
      id: "0",
    },
  ],
  confetti: { show: false, time: null },
};

// ==============================|| SLICE - MENU ||============================== //

const masterData = createSlice({
  name: "masterData",
  initialState,
  reducers: {
    setCategories(state, action) {
      return { ...state, categories: action.payload?.categories };
    },
    setMenuAttractions(state, action) {
      return { ...state, menuAttractions: action.payload?.attractions };
    },
    setHomeBanners(state, action) {
      return { ...state, homeBanners: action.payload?.homeBanners };
    },
    setModalGalleryImages(state, action) {
      return { ...state, modalGalleryImages: action.payload?.images };
    },
    setHomeSections(state, action) {
      return { ...state, homeSections: action.payload?.sections };
    },
    setConfetti(state, action) {
      return { ...state, confetti: action.payload?.confetti };
    },
  },
});

export default masterData.reducer;

export const {
  setCategories,
  setMenuAttractions,
  setHomeBanners,
  setModalGalleryImages,
  setHomeSections,
  setConfetti,
} = masterData.actions;
